import {
  ref, watch, getCurrentInstance, onMounted,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';
import { useRouter } from '@/@core/utils/utils';

export default function useContractList() {
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();

  const blankItem = {

    id: 0,
    //
    apartment: null,
    room: null,
    bed: null,
    //
    contractNumber: '',
    signDate: moment(new Date()).toDate(),
    startDate: moment(new Date()).toDate(),
    endDate: moment(new Date()).add(1, 'Y').subtract(1, 'd').toDate(),
    paymentDay: 0,
    //
    price: null,
    promotionMonth: 0,
    promotionPricePerMonth: 0,
    promotionDeposit: 0,
    paymentPeriod: null,
    billingDate: moment(new Date()).toDate(),
    contractTemplate: null,
    invoiceTemplate: null,
    //
    reservation: null,
    deposit: null,
    reservationDeposit: null,
    remainDeposit: null,

    // tenant
    tenants: [],
    contractTenants: [],
    fees: [],
    contractFees: [],

    // attachment
    attachments: [],

    // handover
    handover: [],
    otherHandover: [],
  };
  const { route } = useRouter();
  const routeParams = route.value.params;
  const item = ref(routeParams && routeParams.contractId && routeParams.contractId > 0 ? { id: routeParams.contractId } : JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = routeParams && routeParams.contractId && routeParams.contractId > 0 ? { id: routeParams.contractId } : JSON.parse(JSON.stringify(blankItem));
  };
  const vm = getCurrentInstance().proxy;
  onMounted(() => {
    if (routeParams && routeParams.contractId && routeParams.contractId > 0) {
      vm.$bvModal.show('modal-contract-detail');
    }
  });

  const isHidden = !vm.$can('update', 'contract') && !vm.$can('delete', 'contract') && !vm.$can('extend', 'contract') && !vm.$can('move', 'contract') && !vm.$can('leave', 'contract') && !vm.$can('liquid', 'contract');
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  // Table Handlers
  const columns = [
    {
      label: t('Số hợp đồng'),
      field: 'contractNumber',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Đại diện'),
      field: 'name',
    },
    {
      label: t('Giá thuê'),
      field: 'price',
      type: 'number',
    },
    {
      label: t('Ngày bắt đầu'),
      field: 'startDate',
    },
    {
      label: t('Ngày kết thúc'),
      field: 'endDate',
    },
    {
      label: t('Trạng thái'),
      field: 'status',
    },
    {
      label: t('Người tạo'),
      field: 'creator',
      hidden: !isTingTong,
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  // filter
  const month = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref({ title: t('Đang thuê'), id: 0 });
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { contractStatus: 0 },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchContracts = () => {
    isLoading.value = false;
    store
      .dispatch('contract/fetchContracts', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('contract/fetchAnalytics', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchUserConfiguration = () => {
    store
      .dispatch('setting/fetchUserConfiguration', serverParams.value);
  };

  const fetchData = () => {
    fetchAnalytics();
    fetchContracts();
    fetchUserConfiguration();
  };

  const downloadImportTemplate = data => {
    store
      .dispatch('contract/generateImportContractTemplate', { filter: { apartmentId: data && data.apartment && data.apartment.id ? data.apartment.id : null } })
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('contract/importContracts', formData)
      .then(() => {
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('contract/exportContracts', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteContracts = contracts => {
    store
      .dispatch('contract/deleteContracts', {
        ids: contracts.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const recoverContract = contractId => {
    store
      .dispatch('contract/recoverContract', contractId)
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const onExtendContract = val => {
    item.value = { ...val, endDate: val.endDate, isExtend: true };
  };

  const onUpdateLeavingContract = val => {
    item.value = { ...val, leavingDate: val.leavingDate, isExtend: false };
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.contractStatus = val.id;
    } else {
      delete filter.contractStatus;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(route, val => {
    if (val.params && val.params.contractId && val.params.contractId > 0) {
      onEditItem({ id: val.params.contractId });
      vm.$bvModal.show('modal-contract-detail');
    }
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.startDateMonth = month.value;
    } else {
      delete filter.startDateMonth;
    }
    updateParams({ filter });
  });

  const getRemainDaysVariant = remainDays => {
    if (remainDays > 30) {
      return { variant: "light-primary", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays > 0) {
      return { variant: "light-warning", text: `Sắp hết hạn (còn ${Math.abs(remainDays)} ngày)` };
    }
    if (remainDays === 0) {
      return { variant: "light-warning", text: `Hết hạn hôm nay` };
    }
    return { variant: "light-danger", text: `Quá hạn ${Math.abs(remainDays)} ngày` };
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    analytic,
    item,
    columns,
    rows,
    apartment,
    room,
    bed,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    month,
    fetchData,
    fetchContracts,
    deleteContracts,
    resetItem,
    onEditItem,
    onExtendContract,
    onUpdateLeavingContract,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    getRemainDaysVariant,
    downloadImportTemplate,
    importData,
    exportData,
    resolveColWidthIfDisableBed,
    recoverContract,
    t,
  };
}
