var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('small',{staticClass:"text-danger pt-2"},[_vm._v(_vm._s(_vm.error))]),_c('vue-good-table',{staticClass:"pt-2",attrs:{"columns":_vm.columns,"rows":_vm.tenants,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'isRepresent')?_c('span',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},on:{"change":function($event){return _vm.onChangeRepresent( $event, props.row)}},model:{value:(props.row.isRepresent),callback:function ($$v) {_vm.$set(props.row, "isRepresent", $$v)},expression:"props.row.isRepresent"}})],1):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":_vm.t('Khách hàng'),"hide-edit":true},on:{"on-delete":function($event){return _vm.onDelete(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }